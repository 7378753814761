





























import Component from 'vue-class-component';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { OnboardingGetters } from '@/modules/onboarding/store/getters';
import { IApiTalent } from '@/shared/models/IApiTalent';
import OnboardingModule from '@/modules/onboarding';
import { OnboardingActions } from '@/modules/onboarding/store/actions';
import RsFile from '@/shared/components/fields/file.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsInput from '@/shared/components/fields/input.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import { AUTH_SERVICE } from '@/modules/onboarding/services/auth.service';

@Component({
  name: 'rs-form-profile-settings-password',
  components: { RsButton, RsSelect, RsInput, RsIcon, RsFile },
})
export default class FormProfileSettingsPassword extends Vue {
  @Action(OnboardingActions.SET_USER, { namespace: OnboardingModule.namespace }) setUser: (user: IApiTalent) => void;
  @Getter(OnboardingGetters.GET_USER, { namespace: OnboardingModule.namespace }) user: IApiTalent;

  sending = {
    form: false,
  };

  error = '';
  success = '';

  submit() {
    this.error = '';
    this.success = '';
    this.sending.form = true;
    AUTH_SERVICE.changePassword(this.user.mainEmail)
      .then(() => {
        this.$track.sendEvent('ProfileSettings', { section: 'password' });
        this.success = 'Password reset link has been sent to your email';
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.sending.form = false;
      });
  }
}
