



































import Component from 'vue-class-component';
import Vue from 'vue';
import RsFormProfileSettingsPassword from '@/modules/dashboard/modules/profile/components/forms/settings/password.vue';
import RsFormProfileSettingsPersonalInfo
  from '@/modules/dashboard/modules/profile/components/forms/settings/personal-info.vue';

@Component({
  components: {RsFormProfileSettingsPersonalInfo, RsFormProfileSettingsPassword}
})
export default class ProfileSettings extends Vue {
  tab = 'personal';

  mounted(){
    this.$track.sendEvent('ProfileSettingsPage')
  }
}
